document.addEventListener("DOMContentLoaded", function() {
  /* START MUSIC */
  const playButton = document.querySelector(".play-music");
  const music = document.getElementById("background-music");
  const musicText = document.getElementById("music-text");
  let isPlaying = false;

  if (playButton && music && musicText) {
    playButton.addEventListener("click", function() {
      if (isPlaying) {
        music.pause();
        musicText.textContent = "PLAY";
      } else {
        music.play();
        musicText.textContent = "PAUSE";
      }
      isPlaying = !isPlaying;
    });

    music.addEventListener("ended", function() {
      music.currentTime = 0;
      music.play();
    });
  } else {
    console.error("Play button, music element, or music text element not found.");
  }
  /* END MUSIC */

  /* START THEATER */
  const theater = document.getElementById('theater');
  const theaterTrigger = document.getElementById('theater-trigger');
  const theaterExit = document.getElementById('theater-exit');
  const streamVideo = document.getElementById("streamVideo");
  const theaterSound = document.getElementById('theater-sound');
  let player;

  // V1 - Movie duration (1 hour, 35 minutes, 47 seconds)
  // const videoDurationSeconds = (1 * 60 * 60) + (35 * 60) + 47;

  // Movie duration duration (1 hour, 30 minutes, 59 seconds)
  const videoDurationSeconds = (1 * 60 * 60) + (30 * 60) + 59;


  // Function to format the time in hms format
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h${minutes}m${remainingSeconds}s`;
  }

  // Function to start the video and set the poster at the current video time
  function startVideo() {
    const liveStartTime = 1725478037055; // Placeholder timestamp for testing
    const now = new Date().getTime();
    
    // Calculate how many seconds since the video started
    let secondsSinceStart = Math.floor((now - liveStartTime) / 1000);
    if (secondsSinceStart < 0) secondsSinceStart = 0;

    // Calculate the current time in the video using the modulus operator for looping
    const videoStartTime = secondsSinceStart % videoDurationSeconds;

    // Safety check to prevent the start time from matching or exceeding the video duration
    if (videoStartTime >= videoDurationSeconds) {
      videoStartTime = 0;  // Fallback to the beginning of the video
    }

    // Format the start time for logging purposes
    const formattedStartTime = formatTime(videoStartTime);

    // Create the poster URL with the formatted time (in hms format)
    const thumbnailPoster = `https://customer-8pzv20ehpnqqzikp.cloudflarestream.com/192403b6529dcad253c0e512ca33067e/thumbnails/thumbnail.jpg?time=${formattedStartTime}&height=600`;

    // Build the video iframe URL with autoplay and the poster at the correct timestamp
    const videoSrc = `https://customer-8pzv20ehpnqqzikp.cloudflarestream.com/192403b6529dcad253c0e512ca33067e/iframe?preload=true&poster=${encodeURIComponent(thumbnailPoster)}&startTime=${videoStartTime}&autoplay=true&muted=true&controls=false&loop=true`;

    // Set the iframe's src to start the video and display the poster at the correct timestamp
    streamVideo.src = videoSrc;
    player = Stream(streamVideo);
    
    // Store the initial muted state
    let isMuted = player.muted;

    if (theaterSound && streamVideo) {
      theaterSound.addEventListener('click', function(event) {
        event.preventDefault();
        
        // Toggle the muted attribute of the video using the stored value
        isMuted = !isMuted;
        player.muted = isMuted;

        // Update the link's text and title based on the current mute state
        if (isMuted) {
          theaterSound.textContent = "Unmute";
          theaterSound.setAttribute("title", "Unmute");
          document.getElementById('theater-sound').classList.add('muted');
        } else {
          theaterSound.textContent = "Mute";
          theaterSound.setAttribute("title", "Mute");
          document.getElementById('theater-sound').classList.remove('muted');
        }
      });
    }

    // Start the timer to refresh the page after video duration (for testing, 2 minutes)
    setTimeout(function() {
      location.reload(); // Force refresh the browser after the video duration
    }, videoDurationSeconds * 1000); // Multiply by 1000 to convert to milliseconds
  }

  // Fade in function
  function fadeIn(element) {
    element.style.display = "block"; // Show the element
    element.style.opacity = 0;
    element.style.transition = "opacity 0.5s ease-in-out"; // CSS transition for smoother animation
    requestAnimationFrame(() => {
      element.style.opacity = 1; // Smoothly transition to full opacity
    });
  }

  // Fade out function
  function fadeOut(element, callback) {
    element.style.transition = "opacity 0.5s ease-in-out"; // Smooth transition for fade-out
    element.style.opacity = 0;
    setTimeout(() => {
      element.style.display = "none"; // Hide the element after fade-out
      if (callback) callback();
    }, 0); // Delay matches the duration of the fade effect
  }

  // Click event for theater-trigger
  theaterTrigger.addEventListener('click', function(event) {
    event.preventDefault();
    if (isPlaying) {
      music.pause();
      isPlaying = false;
      musicText.textContent = "PLAY";
    }
    theater.classList.add('open');
    fadeIn(theater);
    startVideo(); // Start the video and set the poster at the current time
  });

   // Function to get URL parameters
  function getUrlParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  // Trigger theater if ?watch=movie is in the URL
  const watchParam = getUrlParam('watch');
  if (watchParam === 'movie') {
    if (theaterTrigger) {
      theaterTrigger.click(); // Trigger the theater to open and start the video
    }
  }

  theaterExit.addEventListener('click', function(event) {
    event.preventDefault();
    fadeOut(theater, () => {
      theater.classList.remove('open');

      if (theaterSound && streamVideo) {
        // Always reset the button to "Unmute"
        theaterSound.textContent = "Unmute";
        theaterSound.setAttribute("title", "Unmute");
        document.getElementById('theater-sound').classList.add('muted');
      }
      
      if (player) {
        player.pause();  // Pause the video
        player.muted = true;  // Always mute the video when the modal closes
      }
      
      streamVideo.src = ''; // Stop the video by clearing the iframe src
    });
  });
  /* END THEATER */
});